// Packages
import styled from 'styled-components'

export const StyledButton = styled.button`
  background-color: ${
    props => props.type === 'primary'
      ? '#37CCBA'
      : '#0078B7'
  };
  border-radius: 0.5rem;
  border: solid 1px ${props => props.type === 'primary' ? '#37CCBA' : '#0078B7'};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  line-height: ${props => props.theme.font.lineHeights.small};
  padding: 0.75rem;
  position: relative;  
`
