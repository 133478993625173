// Packages
import React from 'react'

// Styled components
import { StyledButton } from './styledComponents'

const Button = props => {
  return (
    <StyledButton {...props} type={props.type || 'primary'}>
      {props.children}
    </StyledButton>
  )
}

export default Button
