// Packages
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { navigate } from 'gatsby'
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../../node_modules/slick-carousel/slick/slick.css'

// Assets
import useData from '../../../hooks/useData'
import useTheme from '../../../hooks/useTheme'

// Styled components
import { StyledHero, StlyedHeroImageContainer, StyledButtonBack, StyledButtonNext, StyledArrow, StyledArrowBotton, StyledArrowTop, StyledArrowBack, StyledFluidImage, StyledWrapper } from './styledComponents'

const Hero = ({ slides }) => {  
  const theme = useTheme()
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoints.medium - 1}px)` })
  const isDesktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.medium}px)` })
  const [sliderState, setSliderState] = useState(null)
  const { constants: { sliderCommonSettings } } = useData()
  const [currentSlides, setCurrentSlides] = useState(null)
  
  const sliderSettings = {
    ...sliderCommonSettings    
  }

  const handleNextSlide = () => {
    sliderState.slickNext()
  }

  const handlePreviousSlide = () => {
    sliderState.slickPrev()
  }

  useEffect(() => {
    if (isMobile && !isDesktop) {
      setCurrentSlides(
        slides.map(opt => {          
          return {
            image: opt.mobileImage,
            ...opt
          }
        })
      )
    }

    if (!isMobile && isDesktop) {
      setCurrentSlides(
        slides.map(opt => {          
          return {
            image: opt.desktopImage,
            ...opt
          }
        })
      )
    }
  }, [isMobile, isDesktop])  

  const mappingSlides = currentSlides && currentSlides.map((slide, index) => (
    <StlyedHeroImageContainer 
      key={slide.src + index} 
      onClick={() => {
        if (slide.onClick) slide.onClick()
        if (slide.to) navigate(slide.to)
      }}>
      <StyledFluidImage fluid={slide.image} />      
    </StlyedHeroImageContainer>
  ))

  return ( 
    <StyledHero>
      <StyledWrapper>        
        <Slider ref={slide => { setSliderState(slide)} } {...sliderSettings}>
          {mappingSlides}          
        </Slider>        
         {currentSlides && currentSlides.length > 1 && (
            <StyledButtonBack onClick={handlePreviousSlide}>
              <StyledArrowBack>
                <StyledArrowTop />
                <StyledArrowBotton />
              </StyledArrowBack>
            </StyledButtonBack>
         )}
        {currentSlides && currentSlides.length > 1 && (
          <StyledButtonNext onClick={handleNextSlide}>
            <StyledArrow>
              <StyledArrowTop />
              <StyledArrowBotton />
            </StyledArrow>
          </StyledButtonNext>
        )}
      </StyledWrapper>
    </StyledHero>   
  )
}

export default Hero
