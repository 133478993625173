// Packages
import styled from 'styled-components'

// Packages
import FluidImage from '../FluidImage'

export const StyledFluidImage = styled(FluidImage)`
  margin: -0.1rem 0;
`

export const StlyedHeroImageContainer = styled.div`
  height: 100% !important;
  margin: auto 0;
`

export const StyledButtonBack = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  left: 0;
  opacity: 0.75;
  outline: none;
  padding: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  user-select: none;

  :active {
    outline-color: none;
  }
`

export const StyledButtonNext = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  opacity: 0.75;
  outline: none;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  user-select: none;

  :active {
    outline-color: none;
  }
`

export const StyledArrow = styled.div`
  cursor: pointer;
  height: 2rem;
  width: 0.65rem;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    height: 3.5rem;
    width: 0.75rem;
  }
`

export const StyledArrowBack = styled(StyledArrow)`
  transform: rotate(180deg);
`

export const StyledArrowTop = styled.div`
  background-color: #289EB1;
  height: 3px;  
  position: absolute;
  top: 50%;
  transform-origin: bottom right;
  transform: rotate(45deg);
  width: 100%;
  
  &:after {
    background-color: #289EB1;
    content: '';
    height: 100%;
    left: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const StyledArrowBotton = styled.div`
  background-color: #289EB1;
  height: 3px;  
  position: absolute;
  top: 50%;
  transform-origin: top right;
  transform: rotate(-45deg);
  width: 100%;
  
  &:after {
    background-color: #289EB1;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 100%;
    top: 0;
  }
`

export const StyledHero = styled.section`
  cursor: pointer;
`

export const StyledWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`
